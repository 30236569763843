import http from "../utils/request"


//消息队列列表
export function listTask(data) {
    return http({
        url: '/admin/task/list',
        method: 'post',
        data: data
    })
}

//消息设置为废弃状态
export function setStatus(data) {
    return http({
        url: '/admin/task/status',
        method: 'post',
        data: data
    })
}

//重新执行导表任务
export function setRun(data) {
    return http({
        url: '/admin/task/run',
        method: 'post',
        data: data
    })
}
//删除任务
export function deleteTask(data) {
    return http({
        url: '/admin/task/delete',
        method: 'post',
        data: data
    })
}

// 店铺商品导出
export function fileDownload(data) {
    return http({
        url: '/admin/task/download',
        method: 'post',
        data: data,
        responseType: "blob",
    })
}

