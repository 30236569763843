<template>
  <div class="app-container">
    <div class="searchForm_box">
      <el-form :inline="true" :model="searchForm" size="small">
        <el-form-item>
          <el-input
            placeholder="請輸入內容"
            v-model="searchForm.searchTxt"
            class="input-with-select"
          >
            <el-select
              v-model="searchForm.searchChoose"
              slot="prepend"
              placeholder="請選擇"
              style="width: 130px"
            >
              <el-option label="代理商ID" value="agent_id"></el-option>
              <el-option label="店鋪ID" value="shop_id"></el-option>
            </el-select>
          </el-input>
        </el-form-item>
        <el-form-item label="狀態">
          <el-select v-model="searchForm.status" clearable placeholder="請選擇">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            icon="el-icon-search"
            size="mini"
            @click="handleQuery"
            >搜索</el-button
          >
          <!-- <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button> -->
          <el-button icon="el-icon-delete" size="mini" @click="multDelete"
            >批量刪除</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <el-table
      ref="multipleTable"
      @selection-change="handleSelectionChange"
      row-key="Id"
      :data="taskListData"
      border
      class="table"
      v-loading="loading"
    >
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column label="ID" prop="file_id"></el-table-column>
      <el-table-column label="類型" prop="type" width="110"></el-table-column>
      <el-table-column label="供應商ID" prop="agent_id"></el-table-column>
      <el-table-column label="店鋪ID" prop="shop_id"></el-table-column>
      <el-table-column label="文件路徑" prop="filename" width="180">
        <template slot-scope="scope">
          <el-popover placement="top-start" width="320" trigger="hover">
            <span>{{ scope.row.filename }}</span>
            <span slot="reference">{{ scope.row.ext }}</span>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column label="上傳人" prop="create_by" align="center">
      </el-table-column>
      <el-table-column label="狀態" prop="status">
        <template slot-scope="scope">
          <span v-if="scope.row.status == 1" style="color: #1890ff"
            >未處理</span
          >
          <span v-if="scope.row.status == 2" style="color: darkgreen"
            >已處理</span
          >
          <span v-if="scope.row.status == 3" style="color: red">異常</span>
        </template>
      </el-table-column>
      <el-table-column label="上傳時間" prop="create_time" width="160">
        <template slot-scope="scope">
          {{ scope.row.create_time | setDate }}
        </template>
      </el-table-column>
      <el-table-column label="備註" prop="msg" width="250"></el-table-column>
      <el-table-column label="操作" width="150">
        <template slot-scope="scope">
          <el-button
            v-if="scope.row.status == 1"
            type="text"
            size="small"
            @click="setStatus(scope.row)"
            >廢棄</el-button
          >
          <el-button v-else type="text" size="small" @click="setRun(scope.row)"
            >重新執行</el-button
          >
          <el-button type="text" size="small" @click="downloadExcel(scope.row)"
            >下載</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="page"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
  </div>
</template>

<script>
import {
  listTask,
  setStatus,
  setRun,
  fileDownload,
  deleteTask,
} from "@/api/task";

export default {
  name: "list",
  data() {
    return {
      options: [
        {
          value: "1",
          label: "未處理",
        },
        {
          value: "2",
          label: "已處理",
        },
        {
          value: "3",
          label: "異常",
        },
      ],
      memberSearchForm: {
        phone: "",
        user_type: "",
      },
      loading: true,
      searchForm: {
        searchTxt: "",
        searchChoose: "agent_id",
        agent_id: "",
        shop_id: "",
      },
      page: 1,
      pageSize: 50,
      total: 0,
      record_total: 0,
      taskListData: [],
      multipleSelection: [],
    };
  },
  methods: {
    getTaskList() {
      let params = {
        page: this.page,
        pageSize: this.pageSize,
        status: this.searchForm.status,
        agent_id:
          this.searchForm.searchChoose == "agent_id"
            ? this.searchForm.searchTxt
            : "",
        shop_id:
          this.searchForm.searchChoose == "shop_id"
            ? this.searchForm.searchTxt
            : "",
      };
      this.loading = true;
      listTask(params).then((response) => {
        this.taskListData = response.data.list;
        this.total = response.data.count;
        this.loading = false;
      });
    },
    // 分頁
    handleSizeChange(val) {
      this.pageSize = val;
      this.getTaskList();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getTaskList();
    },
    handleQuery() {
      this.page = 1;
      this.getTaskList();
    },
    resetQuery() {
      this.searchForm = {
        searchChoose: "agent_id",
      };
    },

    setStatus(row) {
      let val = "";
      let params = {
        file_id: row.file_id,
        status: 3,
      };
      this.$modal
        .confirm("確定要<span style='color:red'>廢棄</span>？")
        .then(function () {
          return setStatus(params);
          this.getTaskList();
        })
        .then(() => {
          this.$modal.msgSuccess("操作成功");
        })
        .catch(() => {});
    },
    setRun(row, tag) {
      let val = "";
      let params = {
        file_id: row.file_id,
        status: 1,
      };
      this.$modal
        .confirm("確定要<span style='color:red'>重新執行</span>？")
        .then(function () {
          return setRun(params);
        })
        .then(() => {
          this.$modal.msgSuccess("操作成功");
          this.getTaskList();
        })
        .catch(() => {});
    },
    downloadExcel(row) {
      fileDownload({ file_id: row.file_id })
        .then((res) => {
          let fileName = row.type + "_" + row.ext;
          const content = res;
          const blob = new Blob([content]);
          if ("download" in document.createElement("a")) {
            // 非IE下載
            const elink = document.createElement("a");
            elink.download = fileName;
            elink.style.display = "none";
            elink.href = URL.createObjectURL(blob);
            document.body.appendChild(elink);
            elink.click();
            URL.revokeObjectURL(elink.href); // 釋放URL 對象
            document.body.removeChild(elink);
          } else {
            // IE10+下載
            navigator.msSaveBlob(blob, fileName);
          }
        })
        .catch(() => {});
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    multDelete() {
      let ids = "";
      let idsList = [];
      if (this.multipleSelection.length <= 0) {
        this.$modal.msgWarning("請選擇要刪除的數據");
        return false;
      }
      for (var i in this.multipleSelection) {
        idsList.push(this.multipleSelection[i].file_id);
      }

      this.$modal
        .confirm("確定要刪除？")
        .then(function () {
          return deleteTask({ ids: idsList.toString() });
        })
        .then(() => {
          this.$modal.msgSuccess("操作成功");
          this.getTaskList();
        })
        .catch(() => {});
    },
  },
  created() {
    this.getTaskList();
  },
};
</script>
<style lang="scss">
.hiddenCheck {
  /*visibility: hidden;*/
}

.agentManage {
  padding: 10px;

  > .searchForm_box {
    padding: 10px;
    background: #fff;
    margin: 10px 0;

    .el-form {
      .el-form-item {
        margin-bottom: 10px;
      }
    }
  }
}

.clickDetialBtn {
  color: rgb(24, 144, 255);
  cursor: pointer;
}

.anticon {
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ant-image-mask {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  cursor: pointer;
  opacity: 0.7;
  transition: opacity 0.3s;
}

.imgView-dialog {
  width: 800px;
  height: 800px;
  align-content: center;
  text-align: center;
}
</style>
